
.App {
  text-align: center;
  background: url("../image/projectTab/background_galaxy.webp");
  background-repeat: no-repeat;
  background-size: cover;
}
/* 

.App-logo {
  width: 50px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
