@media only screen and (min-width: 1800px) {
  .lgscreen {
    padding: 0 10vw; /* top, bottom: 0 - right left: 200px*/
  }
}

/* Custom styles using @apply */
.vintage-button {
  @apply bg-[#d6c8af] text-[#3e2723] py-2 px-4 rounded-lg shadow-lg font-bold text-xl;
  @apply border-2 border-[#3e2723] transition-transform duration-300;
}

.vintage-button:hover {
  @apply transform scale-105;
}

.vintage-button:active {
  @apply transform scale-95;
}

/* Define a slower bounce animation */
@keyframes slow-bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.slow-bounce {
  animation: slow-bounce 2s infinite;
}


/* Define a floating animation */
@keyframes floating {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.floating {
  animation: floating 3s ease-in-out infinite;
}