/* index.css (or you can name it globals.css and adjust the import accordingly) */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import other styles */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import '@fortawesome/fontawesome-free/css/all.min.css';

/* Root and body styles */
:root {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  margin: 0 auto;
  top: 0; bottom: 0; left: 0; right: 0;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0 auto;
  padding: 0;
  font-family: 'Merriweather', 'Roboto';
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(248, 248, 248);
}

* {
  margin: 0;
  box-sizing: border-box;
}

