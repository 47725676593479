.white-shadow {
  box-shadow: 0 4.5px 7px rgba(255, 255, 255, 0.1);
}

.blue-shadow {
  position: relative;
  overflow: hidden;
}

.blue-shadow::before {
  content: '';
  position: absolute;
  left: -30px; /* Start slightly off-screen to the left */
  bottom: 0; /* Align at the bottom or adjust as needed */
  width: 30px; /* Width of the dot */
  height: 2px; /* Height of the dot, making it circular */
  background-color: #03f2ffc0; /* Color of the dot */
  border-radius: 75%; /* Makes the dot circular */
  box-shadow: 0 0 8px rgba(3, 242, 255, 0.579); /* Glow effect for the dot */
  transition: left 0.3s ease; /* Smooth transition for moving the dot */
}

.blue-shadow:hover::before {
  left: calc(100% - 10px); /* Move to the right end of the container minus the width of the dot */
}

.arrow-animation {
  display: inline-block;
  white-space: nowrap;
  animation: moveArrow 2s linear infinite;
}

@keyframes moveArrow {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
}

/* this animation for the background of the intro */
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes flashing {
    0%, 100% { opacity: 1; }
    75% { opacity: 0; }
}

.flash-icon {
    animation: flashing 2s infinite;
}

.active-card {
    border: 3px solid #4a90e2; /* Blue border for visibility */
}


