$black: #121518; // A deep, muted blue
$white: #f7f1e3; // A creamy white
$accent: #f84f40c4; // A vibrant coral for a vintage pop

@mixin media($query) {
    @if $query =="<=phone" {
        @media (max-width: 600px) {
            @content;
        }
    }
}

.preloader {
    height: 100vh;
    width: 100%;
    background: $black;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    overflow: hidden;
    font-family: 'Playfair Display', serif; // Classy vintage font
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.7); // Soft shadow

    .texts-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 12px;
        height: auto;
        width: auto;
        font-size: 28px;
        font-weight: bold;
        opacity: 0;
        color: $white;
        text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6);
        transform: rotate(-5deg); // Slight tilt for a retro feel

        @include media("<=phone") {
            font-size: 24px;
            transform: rotate(0deg);
        }

        img.loading-icon {
            width: 100px;
            height: 100px;
            animation: zoom 2s infinite ease-in-out;
            margin-bottom: 10px;
        }

        @keyframes zoom {

            0%,
            100% {
                transform: scale(1);
            }

            50% {
                transform: scale(1.5);
            }
        }



        span {
            background: $accent;
            padding: 5px 10px;
            border-radius: 50px;
            animation: shadow 1s infinite ease-in-out;
            box-shadow: 0px 0px 5px 2px rgba(15, 58, 61, 0.651);
            border: 2px solid rgba(230, 232, 233, 0.589);
        }

        @keyframes shadow {

            0%,
            100% {
                box-shadow: 0px 0px 5px 2px rgba(15, 58, 61, 0.651);
            }

            50% {
                box-shadow: 0px 0px 15px 5px rgba(37, 108, 165, 0.404);
            }
        }
    }
}