
.PhotoProfile {
  width: 100%;
}

.slide {
  transform: scale(0.2);
  transition: transform 300ms;
  opacity: 0.5;
  position: relative;
}

.slide-inner {
  border: 4px solid rgba(255, 255, 255, 0.815);
  /* Add a white border */
  border-radius: 10px;
  /* Add rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Add a subtle shadow */
  overflow: hidden;
  /* Ensure that the border and shadow don't overflow */
}

.slide img {
  width: 100%;
  /* Adjust the width as needed */
  height: auto;
  /* Maintain aspect ratio */
  margin: 0 auto;
}

.activeSlide {
  transform: scale(1);
  opacity: 1;
}

.slide-content {
  text-align: left;
  max-height: 11rem;
  /* Equivalent to 24px for max-h-24 */
}

/* style for pop up tittle */
@keyframes popUp {
  from {
    opacity: 0;
    transform: translateY(40px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-content {
  animation: popUp 2s ease-in-out;

}

/* custom tittle of the profile photo */

@keyframes moveLeftToRight {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.custom-tittle h2 {
  padding-top: 0;
  white-space: nowrap;
  animation: moveLeftToRight 11s linear infinite;
  position: fixed;
  color: white;
  text-shadow: 2px 2px black;
  font-size: 9em;
  z-index: -1;
}

/*  */
.code-container {
  /* white-space: pre-wrap; */
  font-weight: 600;
  overflow-x: auto;
  padding: 5px 7px;
  border: 1px solid #ddd;
  /* Add border for clarity */
  border-radius: 7px;
}

/* custom the dynamic box */
.custom-box {
  position: relative;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  /* background-color: rgba(0,0,0,0.5); */
  border-radius: 20px;
  overflow: hidden;

  height: 100vh;
  width: 100vw;
  max-width: 100%;
  /* Ensure it doesn't exceed 100% of the viewport width */
  box-sizing: border-box;
  /* Include padding and border in the element's total width and height */
  transition: height 0.2s ease, width 0.2s ease;
  /* Smooth transition for height and width */
  padding: 6px 0 10px 0;
}


.custom-box::before {
  content: '';
  position: absolute;
  background: linear-gradient(#111111, #817583);
  animation: rotate 10s linear infinite;
}

.custom-box:after {
  content: '';
  position: absolute;
  background: url("../image/bg_flower.jpg");
  inset: 5px;
  border-radius: 16px;
  background-size: cover;
  overflow: hidden;
}


/* tag fpr the intro slides */

.tag {
  cursor: pointer;
  padding: 2px 10px;
  border: #111111;
  border-radius: 75%;
  opacity: 75%;
}

.tag.active {
  background-image: linear-gradient(to right, #ffffff2d 0%, #fff8c388 100%);
  /* Change to desired active background color */
  color: #232325;
  /* Change to desired active text color */
  font-weight: 700;
  opacity: 100%;
  
}

.tag:not(.active):hover {
  text-decoration: none;
  /* Remove default underline */
  border-bottom: 2px solid rgba(0, 0, 0, 0.73);
  /* Add white underline on hover for non-active tags */
}

@keyframs rotate {
  from {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(360deg)
  }
}

/* Styles for large screens (e.g., 2k screens) */
@media only screen and (min-width: 1440px) {
  .custom-box {
    height: 100%;
    width: 100%;
  }

  .PhotoProfile {
    width: 20vw;
    height: auto;
  }

}

@media only screen and (max-width: 1439px) and (min-width: 640px) {
  .custom-box {
    height: 80vh;
    height: 100%;
    width: 100%;
  }

  .PhotoProfile {
    width: 30vw;
    height: auto;
  }

  /* Styles for medium screens */
  @media only screen and (max-width: 767px) and (min-width: 640px) {
    .PhotoProfile {
      width: 40vw;
      height: auto;
    }
    .custom-box {
      margin-block: 2px;
      max-height: calc(100% - 4rem);

    }
  }
}


/* Styles for small screens */
@media only screen and (max-width: 639px) {
  .custom-box {
    margin: 10px 5px 4rem 5px;
    /* minus of the bottom bar */
    max-height: calc(100% - 2rem);
    max-width: calc(100% - 0.6rem);
    /* minus of the bottom bar */
    /* align-self: flex-start; */
  }

  .custom-box::before {
    width: 25vh;
    height: 1000vw;
  }

  .PhotoProfile,
  .ExpandedPhotoProfile {
    height: auto;
    margin-top: -100px;
    transition: transform 0.5s ease-in-out;
    /* Smooth transition for transform */
  }

  .PhotoProfile {
    padding-top: 1.75rem;
    width: 11rem;
  }

  /* .ExpandedPhotoProfile {
    width: 30vw;
    transform: scale(0.8);
  } */
  .slide-content {
    max-height: 9rem;
  }

}

@media only screen and (max-width: 380px) {
  .custom-box {
    margin: 0px 5px 4rem 5px;
    max-height: calc(100% - 4rem);
    width: 95vh;
  }

}