@keyframes slideText {
  0%, 100% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(-30%);
  }
}

.contactFont {
  display: inline-block; /* Allows the transform to work */
  white-space: nowrap; /* Keeps the text in a single line */
  overflow: hidden; /* Ensures the overflow text is hidden */
  animation: slideText 2s linear infinite; /* Apply the animation */
}
